<template>
  <div>
    <div class="my-6 flex items-center justify-between">
      <div>
        <h3 class="font-bold">
          Edit project <span class="text-primary">{{ data.name }}</span>
        </h3>

        <span class="text-sm text-gray-500">Verify the following information and click the <b>save</b> button to
          confirm changes.
        </span>
      </div>
      <div class="flex justify-end">
        <Button @click="edit" color="primary" text="Save" />
      </div>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <span class="lg:inline-grid w-full grid-cols-2 gap-4 mb-6">
        <div class="flex-1 mr-3">
          <Input v-model="data.name" type="default" label="Name" placeholder="Project Name" />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.sprint" type="default" label="Business" placeholder="Business" />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.status" type="select" label="Status" placeholder="Status" :data="status.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.start" type="date" label="Start Date" placeholder="Start Date" />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.finish" type="date" label="Finish Date" placeholder="Finish Date" />
        </div>


        <Input v-model="data.required_hours" type="default" label="Required Hours" placeholder="Hours" />
        <div class="flex-1 mr-3">
          <Input v-model="data.client" type="default" label="Client Account" placeholder="Client Account" />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.manager" type="select" label="Manager" placeholder="Manager" :data="managers.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>
        <div class="flex-1 mr-3">
          <Input v-model="data.coordenator" type="select" label="Coordinator" placeholder="Coordinator" :data="coordenators.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.teamleader" type="select" label="TeamLeader" placeholder="Team Leader" :data="teamleaders.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.clientId" type="select" label="Client" placeholder="Client" :data="clients.map((elemento) => {
            return {
              label: elemento.name,
              value: elemento.id,
            };
          })
            " />
        </div>

        <div class="flex-1 mr-3">
          <Input v-model="data.sector" type="default" label="Sector" placeholder="Sector" />
        </div>
      </span>
      <div class="w-full grid-cols-2 gap-x-4 gap-y-4 mb-6">
        <div class="flex flex-col relative auto border rounded-lg bg-white appearance-none py-4">
          <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
            Modelers</span>
          <div class="flex mr-3" v-for="item in users" :key="item.name">
            <div class="block flex items-center h-5 ml-2">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                :id="item.id" :value="item.id" v-model="modelators" />
            </div>
            <div class="ml-1 text-sm">
              <label :for="item.value" class="font-medium text-gray-700">
                {{ `${item.name} - ${item.user_function}` }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PATCH, DELETE, POST } from "../../services/api";
import { toDateUS } from "../../services/formater";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editProjects",
  components: {
    Input,
    Button,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const data = ref({});
    const managers = ref([]);
    const users = ref([]);
    const coordenators = ref([]);
    const teamleaders = ref([]);
    const modelators = ref([]);
    const clients = ref([]);
    const status = ref([]);


    onMounted(async () => {
      loader.open();
      try {

        const res = await GET("users");


        status.value = await GET('status')

        users.value = res.filter((el) => el.role === 2).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        managers.value = res.filter((el) => el.role === 3).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        coordenators.value = res.filter((el) => el.role === 4).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        teamleaders.value = res.filter((el) => el.role === 6).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        clients.value = res.filter((el) => el.role === 5).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
        const team = await GET(`team/${route.params.id}/project`);

        team.map((el) => {
          modelators.value.push(el.user.id);
        });

        data.value = await GET(`projects/${route.params.id}`);
        data.value.data.required_hours = data.value.data.required_hours / 60;
        data.value = data.value.data;
        

        if (data.value.start) {
          data.value.start = data.value.start.split('T')[0]
        }

        if (data.value.finish) {
          data.value.finish = data.value.finish.split('T')[0]
        }

        if (data.value.coordenator) {
          const coordenator = data.value.coordenator;
          data.value.coordenator = coordenator.id;
        }

        if (data.value.teamleader) {
          const teamleader = data.value.teamleader;
          data.value.teamleader = teamleader.id;
        }

        if (data.value.manager) {
          const manager = data.value.manager;
          data.value.manager = manager.id;
        }
        if (data.value.clientId) {
          const client = data.value.clientId
          data.value.clientId = client.id
        }



        loader.close();
      } catch (e) {
        console.log(e)
        loader.close();
        if (
          e.includes("Token") ||
          e.includes("t_dynamik") ||
          e == "Unauthorized"
        ) {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: "/login" });
        }
        alert.open("Error", e, "danger");
      }
    });

    const edit = async () => {
      modal.open(
        "Attention",
        "Do you really wish to edit this project?",
        "warning",
        "Yes",
        async () => {
          const obj = {
            name: data.value.name,
            sprint: data.value.sprint,
            required_hours: data.value.required_hours * 60,
            client: data.value.client,
            coordenator: data.value.coordenator,
            teamleader: data.value.teamleader,
            manager: data.value.manager,
            start: data.value.start,
            finish: data.value.finish,
            sector: data.value.sector,
            status: data.value.status
          };
          const validate = isEmpty(obj);
          if (validate) {
            validate && alert.open("Attention!", validate, "warning");
          } else {
            try {
              loader.open();
              /*if(item === 'deleted_at'){
                const year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                month = month < 10 ? '0'+month : month;
                let day = new Date().getDate();
                day = day < 10 ? '0'+day : day;
                
                data.value['deleted_at'] = data.value.status === 'ATIVA' ? ' ' : `${year}-${month}-${day}`;
              }
            }*/
              const response = await PATCH(`projects/${route.params.id}`, obj);
              // delete all team from project then create again
              const deleteTeam = await DELETE(
                `team/${route.params.id}/project`
              );

              const team = modelators.value.map((elemento) => {
                const body = {
                  project: route.params.id,
                  user: elemento,
                };

                return body;
              });

              await POST("team", team);

              alert.open(
                "Success!",
                `Project was successfully edited!`,
                //<b>${response.name}</b>
                "success"
              );
              loader.close();
              router.push("/projects");
            } catch (e) {
              loader.close();
              if (
                e.includes("Token") ||
                e.includes("t_dynamik") ||
                e == "Unauthorized"
              ) {
                localStorage.removeItem("t_dynamik");
                localStorage.removeItem("info_dynamik");
                router.push({ path: "/login" });
              }
              alert.open("Error", e, "danger");
            }
          }
        },
        modal.close()
      );
    };

    return {
      edit,
      data,

      users,
      coordenators,
      teamleaders,
      managers,
      modelators,
      clients,
      status
    };
  },
};
</script>

<style></style>