
import { defineComponent, onMounted } from 'vue';
import Sidebar from '../components/Elements/Sidebar.vue'
import Navbar from '../components/Elements/Navbar.vue'


export default defineComponent({
  name: 'App',
  components: {
    'sidebar': Sidebar,
    'navbar': Navbar,
   
  },
});
