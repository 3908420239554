<template>
  <div class="my-6">
    <h3 class="font-bold">
      Create a new {{ route.name.split("/")[0].slice(0, -1).toLowerCase() }}
    </h3>
    <span class="text-sm text-gray-500"
      >Fill the following information and click the <b>create</b> button to save changes.
    </span>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-2 gap-3 mb-6">
      <div class="flex-1 mr-2">
        <Input
          v-model="data.name"
          type="default"
          label="Name"
          placeholder="User Name"
        />
      </div>
      <div class="flex-1 mr-2">
        <Input
          v-model="data.user_function"
          type="default"
          label="Occupation"
          placeholder="User Occupation"
        />
      </div>
      <div class="flex-1 mr-2">
        <Input
          v-model="data.email"
          type="default"
          label="Email"
          placeholder="User Email"
        />
      </div>
      <div class="flex-1 mr-2">
        <Input
          v-model="data.password"
          type="password"
          label="Password"
          placeholder="User Password"
        />
      </div>
      <div class="flex-1 mr-2">
        <Input
            v-model="data.role"
            type="select"
            label="Role"
            placeholder="Select an item"
            :data="[
              { label: 'Admin', value: 1 },
              { label: 'Modeler', value: 2 },
              { label: 'Manager', value: 3 },
              { label: 'Coordinator', value: 4 },
              { label: 'Client', value: 5 },
              { label: 'Team Leader', value: 6 }
            ]"
          />
      </div>
    </span>
  </div>
  
  <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Create" />
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref, } from "vue";
import { POST } from "../../services/api";
import { isEmpty, isEmail } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "createUsers",
  components: {
    Input,
    Button,
  },

  setup() {
    const alertProps = inject("alert");
    const data = ref({
      name: "",
      user_function: "",
      email: "",
      password: "",
      role: 2
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();

    const create = async () => {
      //const uniqueCodeValidate = isUniqueCode(data.value.unique_code);
      const emailValidate = isEmail(data.value.email);
      const validate = isEmpty(data.value);
      if (validate || !emailValidate) {
        !emailValidate && alert.open("Attention!", 'Email must be valid.', "warning");
        validate && alert.open("Attention!", validate, "warning");
      } else {
        loader.open();
        try {
          console.log(data.value);
          const response = await POST("users", data.value);
          alert.open(
            "Success!",
            `User was successfully created!`,
            //<b>${response.name}</b>
            "success"
          );
          loader.close();
          router.push("/users");
        } catch (e) {
          loader.close();
          if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
      }
    };
    return {
      alertProps,
      data,
      create,
      route,
    };
  },
};

</script>

<style>
.ck-content {
  font-family:Lato;
  line-height: 24px;
}
.ck-editor__editable_inline {
    min-height: 400px;
}
.ck-content h2 {
  font-size: 22px;
}
.ck-content h3 {
  font-size: 20px;
}
.ck-content h4 {
  font-size: 18px;
}
.conteudo-dica {
  font-family:Lato;
  line-height: 24px;
}
.conteudo-dica h2 {
  font-size: 22px;
}
.conteudo-dica h3 {
  font-size: 20px;
}
.conteudo-dica h4 {
  font-size: 18px;
}
figure img {
  margin: 0 auto;
}
</style>