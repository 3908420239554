import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid overflow-x-hidden bg-gray-50" }
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "main p-8 container mx-auto" }
const _hoisted_4 = { class: "navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_loading = _resolveComponent("loading")!
  const _component_alertbox = _resolveComponent("alertbox")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_navbar = _resolveComponent("navbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal),
    _createVNode(_component_loading),
    _createVNode(_component_alertbox),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_sidebar)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_navbar)
      ])
    ])
  ], 64))
}