<template>
  <div>
    <div class="my-6">
      <h3 class="font-bold">List of {{ route.name }}</h3>
      <span class="text-sm text-gray-500"
        >Here you can observe all items related to the category.
      </span>
    </div>
    <div class="flex mb-6 justify-end">
      <router-link to="/categories/create">
        <Button
          color="primary"
          :text="`Create new category`"
        />
      </router-link>
    </div>
    <div class="card">
      <DataTable
        :headers="headers"
        :data="data"
        :options="options"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "categories",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Name", value: "name" },
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("categories");
        loader.close();
      } catch (e) {
        loader.close();
        if (
          e.includes("Token") ||
          e.includes("t_dynamik") ||
          e == "Unauthorized"
        ) {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: "/login" });
        }
        alert.open("Error", e, "danger");
      }
    });

    const deleteItem = (project) => {
      loader.open();
      const deleteFunction = async function () {
        try {
          loader.open();
          await DELETE(`categories/${project.id}`);
          data.value = data.value.filter((item) => item.id != project.id);
          loader.close();
          alert.open(
            "Success!",
            `Category was deleted successfully!`,
            "success"
          );
        } catch (e) {
          loader.close();
          if (
            e.includes("Token") ||
            e.includes("t_dynamik") ||
            e == "Unauthorized"
          ) {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: "/login" });
          }
          alert.open("Error", e, "danger");
        }
      };
      modal.open(
        "Attention",
        "Do you really wish to delete this category?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const options = [
      { icon: "pen", path: "/categories/edit", title: "Edit label" },
      {
        icon: "trash",
        action: (param) => deleteItem(param),
        title: "Delete label",
      },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style>
</style>