<template>
  <div class="my-6">
    <h3 class="font-bold">List of {{ route.name }}</h3>
    <span class="text-sm text-gray-500">Here you can observe all items related to the {{ route.name.toLowerCase() }}.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <router-link to="/users/create">
      <Button color="primary" :text="`Create new ${(route.name.slice(0, -1)).toLowerCase()}`" />
    </router-link>
  </div>
  <div class="card">
    <DataTable :headers="headers" :data="data" :options="options" color="primary" />
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE, PATCH } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "users",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Name", value: "name" },
      { key: "Function", value: "user_function" },
      { key: "Status", value: "active" },
      { key: "Last Update", value: "updated_at", date: true },
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        const users = await GET("users");
        data.value = users.map(el => {
          const schema = {
            ...el,
            active: el.status == true ? 'active' : 'desactive'
          }

          return schema

        }).sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))
        loader.close();
      } catch (e) {
        loader.close();
        if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: '/login' });
        }
        alert.open("Error", e, "danger");
      }
    });

    const deleteItem = (user) => {
      loader.open();
      const deleteFunction = async function () {
        try {
          loader.open();
          await DELETE(`users/${user.id}`);
          data.value = data.value.filter((item) => item.id != user.id);
          loader.close();
          alert.open("Success!", `User <b>${user.name}</b> was deleted successfully!`, "success");
        } catch (e) {
          loader.close();
          if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: '/login' });
          }
          alert.open("Error", e, "danger");
        }
      }
      modal.open(
        "Attention",
        "Do you really wish to delete this user?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const changeStatus = (user) => {
      try {

        const statusFunction = async function () {
          try {
            loader.open();
            await PATCH(`users/${user.id}`, { status: !user.status });



            const users = await GET("users");
            data.value = users.map(el => {
              const schema = {
                ...el,
                active: el.status == true ? 'active' : 'desactive'
              }

              return schema

            }).sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))


            loader.close();
            alert.open("Success!", `User <b>${user.name}</b> was update successfully!`, "success");
          } catch (e) {
            loader.close();
            if (e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
              localStorage.removeItem("t_dynamik");
              localStorage.removeItem("info_dynamik");
              router.push({ path: '/login' });
            }
            alert.open("Error", e, "danger");
          }
        }

        loader.open();
        modal.open(
          "Attention",
          "Do you really wish to change this user status?",
          "warning",
          "Yes",
          () => statusFunction(),
          modal.close()
        );
        loader.close();
      } catch (error) {
        loader.close();
      }
    }

    // const show = (r) => {
    //   router.push(`/parceiros/editar/${r.id}`);
    // };

    const options = [
      { icon: "block", action: (param) => changeStatus(param), title: "Change status", use:'status' },
      { icon: "pen", path: '/users/edit', title: "Edit user" },
      { icon: "trash", action: (param) => deleteItem(param), title: "Delete user" },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style></style>